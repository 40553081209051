<template>
  <div style="padding: 20px;">

    <el-button type="primary" @click="rowCreate">添加</el-button>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)" style="margin-top: 20px;">
      <el-table-column prop="name" label="文件名称" width="180" />
      <el-table-column prop="prop2" label="主机名" width="180" />
      <el-table-column prop="prop3" label="端口" width="180" />
      <el-table-column prop="prop4" label="文件路径" width="180" />
      <el-table-column prop="prop5" label="描述" width="180" />
      <el-table-column prop="prop5" label="创建时间" width="180" />
      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="rowUpdate(row)" type="text" size="small">编辑</el-button>
          <el-button @click="rowRemove(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <edit-form ref="editForm" @success="refresh" />
  </div>
</template>
<script>
import _ from 'lodash';
import EditForm from './EditForm'
export default {
  components: { EditForm },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    }
  },
  watch: {
    currentPage() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 
  methods: {
    async refresh() {
      console.log('refresh')
      this.loading = true;
      const res = await this.$api.execute('file_source.list', _.assign({}, _.pick(this, ['current', 'pageSize'])));
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },

    rowCreate() {
      this.$refs.editForm.show({ }, { title: '新增文件数据源' });
    },
    rowUpdate(obj) {
      this.$refs.editForm.show(obj, { title: '编辑文件数据源' });
    },
    async rowRemove(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('file_source.remove', obj.id)
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    }
  }
}
</script>