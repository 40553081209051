<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button @click="visible = false">测 试</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="数据源">
        <el-select v-model="form.type">
          <el-option label="MYSQL" value="MYSQL"></el-option>
          <el-option label="POSTGRESQL" value="POSTGRESQL"></el-option>
          <el-option label="DB2" value="DB2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文件名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="文件路径">
        <el-input v-model="form.path"></el-input>
      </el-form-item>
      <el-form-item label="主机名">
        <el-input v-model="form.host"></el-input>
      </el-form-item>
      
      <el-form-item label="端口">
        <el-input v-model="form.port"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="本地目录">
        <el-input v-model="form.dir"></el-input>
      </el-form-item>
      <el-form-item label="FTP">
        <el-input v-model="form.ftp"></el-input>
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('file_source.update', this.form.id, _.pick(this.form, ['name']));
        } else {
          await this.$api.execute('file_source.create', _.pick(this.form, ['name']));
        }

        this.$emit('success')
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    }
  }
}
</script>